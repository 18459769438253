import { useFavicon, useStorage, useTitle } from '@vueuse/core';
import apiAxios from '../../libs/apiAxios';

export default {
  namespaced: true,

  // Data
  state: {
    data: {},
  },

  // Get data
  getters: {
    get(state) {
      return state.data;
    },
    isMaintenanceMode(state) {
      return state.data.maintenance_mode;
    },
    isDevMode(state) {
      return state.data.dev_mode;
    },
    hasSpidLogin(state) {
      return state.data.spid_login;
    },
    hasCieLogin(state) {
      return state.data.cie_login;
    },
    hasBookingOnline(state) {
      return state.data.booking_online;
    },
    hasBookingOnlineButton(state) {
      return state.data.booking_online_button;
    },
  },

  // Change data synchronously
  mutations: {
    set(state, payload) {
      state.data = payload;
    },
  },

  // Async methods
  actions: {
    async retrieve(context) {
      const response = await apiAxios.get('/settings', {});
      context.commit('set', response.data);
      await context.dispatch(
        'settings/checkPageTitleAndFavicon',
        { title: response.data.interface_title, favicon: response.data.favicon },
        { root: true },
      );
    },
    checkPageTitleAndFavicon({}, pageData) {
      // Get and check storage for title & favicon
      const objectPage = useStorage(
        'page',
        null,
        undefined,
        {
          serializer: {
            read: (v) => (v ? JSON.parse(v) : null),
            write: (v) => JSON.stringify(v),
          },
        },
      );

      // check if is created the storage || we have title ||  favicon => update storage;
      if (objectPage.value === null
        || (pageData.title !== undefined && pageData.title !== objectPage.value.title)
        || (pageData.favicon !== undefined && pageData.favicon !== objectPage.value.favicon)) {
        objectPage.value = { title: pageData.title ?? '', favicon: (pageData.favicon ?? objectPage.value?.favicon) };

        // use the data and update page title & favicon;
        useTitle(objectPage.value.title);
        useFavicon(objectPage.value.favicon);
      }
    },
  },
};
